<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          :style="{
            color: color,
          }"
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row>
      <v-col
        class="d-flex align-center justify-center justify-md-start align-md-start mt-8 mb-md-4"
      >
        <FlagTitle title="Tomadas de Termo" />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                A Etapa 4 do processo de cadastramento consiste em um
                instrumento complementar aos eixos 3 e 4 do formulário,
                relativos às perdas e aos danos imateriais (danos morais e
                coletivos). A tomada de termo é uma entrevista semiestruturada
                individual, com respostas abertas, aplicada pela Cáritas,
                conduzida por um assessor da área psicossocial, enquanto outro
                assessor faz o relatório do depoimento, e acontece com a
                presença apenas do atingido ou da atingida. Com o objetivo de
                realizar o levantamento dessas lesões com cada sujeito
                cadastrado<sup>1</sup>, há um roteiro de questões primárias que
                marcam o tempo cronológico de antes, durante e após o rompimento
                da barragem de Fundão. É nesse momento que a pessoa atingida
                pode declarar de forma mais explícita suas dores e os
                sofrimentos advindos do rompimento.
              </p>
              <p>
                Quem relata reduz a termo as falas da pessoa atingida, enquanto
                o áudio, quando autorizado pelo entrevistado, é gravado para
                arquivamento interno. Ao final de cada tomada de termo, é
                realizada a leitura completa da transcrição pelo relator,
                momento em que a pessoa atingida pode corrigir, acrescentar e
                retirar o que não estiver de acordo com seu relato e, em
                seguida, o documento é salvo e convertido em .pdf para,
                futuramente, ser anexado de forma integral ao dossiê do núcleo
                familiar.
              </p>
              <p>
                Se fosse inviável a realização presencial da entrevista, ela
                ocorria via videoconferência e, com a pandemia de COVID-19, o
                formato remoto tornou-se a regra. Excepcionalmente, para aquelas
                pessoas que não possuíam acesso à internet ou que assim
                preferiram, algumas entrevistas vêm sendo realizadas
                presencialmente no escritório da Cáritas, respeitados os
                protocolos de segurança orientados pela OMS.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/tomadas_termo_01.png" alt="" />
          <p style="font-size: 0.5em">
            Exercício de simulação da roda de conversa introdutória e da tomada
            de termo individual com participação de Luzia Queiroz, atingida de
            Paracatu de Baixo (à direita da imagem). Foto: Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                A etapa da tomada de termo evidencia o sofrimento físico e
                mental de cada pessoa atingida e escancara as violações
                ocorridas e os processos graves de adoecimento. Durante a
                entrevista, fica nítida nas falas das pessoas atingidas a
                dificuldade de lidar e diferenciar os atores institucionais e o
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_ser_atingido_tempos' }"
                  >cansaço gerado pelas atividades relacionadas ao
                  rompimento</router-link
                >, que passam a constituir uma agenda rotineira. Muitas delas
                expressam seus adoecimentos físicos e mentais oriundos do
                rompimento da barragem e o doloroso processo que se segue nos
                silêncios, na apatia emocional e no esmaecimento de seus
                sentimentos. A condução da tomada de termo exige sensibilidade,
                acolhimento e segurança.
              </p>
              <p>
                Durante a entrevista, a pessoa atingida passa a ter maior
                dimensão das suas perdas e dos danos relativos à saúde mental e
                física, à participação em celebrações, festas e folguedos, às
                formas de expressão e linguagem, aos saberes e modos de fazer,
                às relíquias familiares, à convivência familiar e comunitária,
                ao acesso aos espaços e equipamentos públicos, ao uso do seu
                tempo (excesso de reuniões após o rompimento), ao acesso aos
                bens da natureza, à vida provisória, ao projeto de vida e aos
                conflitos familiares.
              </p>
              <p>
                Finalmente, destaca-se que foram criados outros dois
                instrumentos para levantamento de danos imateriais. Um roteiro
                de tomada de termo específico para as entidades com ou sem
                registro formal, como associações comunitárias, cooperativas,
                igrejas e outras, também atingidas pelo rompimento; e um
                levantamento de danos relacionados às questões de saúde e raça.
                Este último foi desenvolvido em razão de a equipe responsável
                por fazer o documento de valoração específico de cada núcleo
                familiar, acompanhada pela Assessoria Jurídica, verificar a
                necessidade de informações complementares com vistas a incluir
                os valores de indenizações referentes às questões de saúde
                (medicamentos, dosagem, valores gastos com medicamentos, exames,
                consultas médicas e psiquiátricas, internações) e raça, uma vez
                que o produto da Matriz de Danos<sup>2</sup> contratado pela
                Cáritas prevê a inclusão do marcador racial no cálculo da
                valoração dos danos imateriais, para eventual aumento de 10% no
                quantum indenizatório<sup>3</sup>, e esse dado ainda não havia
                sido levantado anteriormente. A condução do levantamento é
                realizada pelos mesmos profissionais que realizam a tomada de
                termo.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/tomadas_termo_02.png" alt="" />
          <p style="font-size: 0.5em">
            Aplicadoras da tomada de termo da equipe da Assessoria Técnica da
            Cáritas. Foto: Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="container-fluid d-flex mt-5">
      <v-col cols="12" class="d-flex flex-column justify-center align-center">
        <div class="inside__card" style="width: 100%;">
          <p>
            <sup>1</sup> Participam da tomada de termo todos os atingidos e
            atingidas membros dos núcleos familiares. Somente não são ouvidas as
            crianças que eram menores de quatro anos no dia do rompimento, salvo
            solicitação do responsável para atendê-las, e os que manifestarem
            expressamente que não querem participar. Ressalta-se que todo
            processo do cadastro é realizado de forma voluntária e as empresas
            não devem ser eximidas de considerar os pleitos daqueles que, por
            quaisquer razões, optam por não se cadastrar ou não passar por todas
            as etapas.
          </p>
          <p>
            <sup>2</sup> Ver a Matriz de Danos em:
            <a
              target="_blank"
              href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/April2021/OPUnMgIE75jp7mstimpn.pdf"
            >
              <span class="pink--text">
                http://mg.caritas.org.br/storage/arquivo-de-biblioteca/April2021/OPUnMgIE75jp7mstimpn.pdf
              </span> </a
            >.
          </p>
          <p>
            <sup>3</sup> Ressalta-se a importância do marcador racial para
            garantir o tratamento equânime entre os atingidos, vez que o racismo
            é estrutural na sociedade brasileira e se observa tratamento
            desigual recebido pela população negra e indígena antes, durante e
            após o desastre-crime. A explicação completa para a utilização desse
            mecanismo pode ser encontrada no produto de danos imateriais da
            Universidade Federal Rural do Rio de Janeiro (UFRRJ), contratado
            pela Cáritas para a fundamentação da Matriz de Danos das pessoas
            atingidas.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 mt-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_sistematizacao_dossie' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Conhecer "Sistematização e dossiê"
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";
import FlagTitle from "../../components/FlagTitle.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    FlagTitle,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro /",
          href: "sobre_cadastro",
        },
        {
          text: "Tomadas de Termo",
          href: "sobre_tomadas_de_termo",
        },
      ],
    };
  },
};
</script>

<style scoped>
h5 {
  color: #c92f05;
}

a {
  text-decoration: none;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__container_card {
  max-width: 40em;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
